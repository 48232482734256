import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { midwivesCollection } from '@hb/shared'
import React, { useContext, useMemo } from 'react'
import { ScreenContext, SearchBarProvider } from '../../contexts'
import {
  ExtraInvoiceColumnProps,
  getPaidInvoicesTabs,
  getSentInvoicesTabs,
  useCollectionItem,
} from '../../hooks'
import { DataList } from '../DataList/DataList'
import { SelectPracticeView } from '../Practices/SelectPracticeView'
import { PageContainer } from '../Views/PageContainer'
import { InvoicesViewProvider, useInvoicesView } from './InvoicesViewProvider'
import { InvoiceViewModal } from './InvoiceViewModal'

const InvoicesSelectedInvoiceView = () => {
  const { selectedInvoiceId } = useInvoicesView()
  return selectedInvoiceId ? <InvoiceViewModal /> : null
}

const InvoicesViewTabList = () => (
  <TabList bg="gray.50" borderTopRadius={6}>
    <Tab>Outstanding</Tab>
    <Tab>Paid</Tab>
    {/* <Center flex={1}>
      <GoToInvoiceByNumber />
    </Center> */}
  </TabList>
)

const InvoicesViewTabs = () => {
  const invoicesView = useInvoicesView()
  const { tabIndex, onTabSelect, selectedPracticeId } = invoicesView
  const { width: screenWidth, contentHeight, isMobile } = useContext(ScreenContext)
  const listHeight = useMemo(() => contentHeight - (isMobile ? 20 : 70), [contentHeight, isMobile])
  const width = useMemo(() => Math.min(screenWidth - 10, 850), [screenWidth])
  const practice = useCollectionItem(midwivesCollection, selectedPracticeId)
  const tabs = useMemo(
    () =>
      selectedPracticeId
        ? {
            outstanding: getSentInvoicesTabs(selectedPracticeId),
            paid: getPaidInvoicesTabs(selectedPracticeId),
          }
        : null,
    [selectedPracticeId],
  )
  const extraColumnProps = useMemo<ExtraInvoiceColumnProps>(
    () => ({
      invoicesView,
      practices: {
        error: practice.error,
        loading: practice.loading,
        items: practice.item ? [practice.item] : [],
      },
    }),
    [invoicesView, practice],
  )
  if (!tabs) {
    return <SelectPracticeView />
  }

  return (
    <SearchBarProvider>
      <Tabs index={tabIndex} onChange={onTabSelect} colorScheme="green" width={width}>
        <InvoicesViewTabList />
        <TabPanels>
          <TabPanel p={0}>
            <DataList
              height={listHeight}
              width={width}
              tabs={tabs.outstanding}
              extraColumnProps={extraColumnProps}
              rootPath="invoices/outstanding"
            />
          </TabPanel>
          <TabPanel p={0}>
            <DataList
              extraColumnProps={extraColumnProps}
              height={listHeight}
              width={width}
              tabs={tabs.paid}
              rootPath="invoices/paid"
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SearchBarProvider>
  )
}

export const PracticeInvoices = () => (
  <PageContainer>
    <InvoicesViewProvider>
      <InvoicesViewTabs />
      <InvoicesSelectedInvoiceView />
    </InvoicesViewProvider>
  </PageContainer>
)
