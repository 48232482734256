import {
  Badge,
  Button,
  Center,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { colors, MIDWIVES, Practice } from '@hb/shared'
import {
  Expandable,
  PopUpMessageContext,
  ScreenContext,
  useAuth,
  useCachedPracticeUser,
  useDocument,
  useMe,
  usePracticeAccess,
} from '@hb/shared-frontend'
import { updateDoc } from 'firebase/firestore'
import React, { FC, useCallback, useContext, useMemo, useState } from 'react'

const SelectedIndicatorSvg: FC<{ isSelected: boolean }> = ({ isSelected }) => (
  <svg
    width="18px"
    height="18px"
    viewBox="-7 -7 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle r="6" strokeWidth="1" stroke="#cdcdcd" />
    <circle r="5.5" fill="#efefef" />
    {isSelected ? <circle r="4" fill={colors.green.hex} /> : null}
  </svg>
)

const PracticeAccessView: FC<{
  practiceId: string
  onOpen: () => void
  isOpen: boolean
  isSelected: boolean
  onSelect: () => Promise<void>
}> = ({ practiceId, isSelected, onSelect }) => {
  const { loading, data } = useDocument<Practice>(MIDWIVES, practiceId)
  // const delayedLoading = useLoadingTimeout(loading, 500)
  const [selectLoading, setSelectLoading] = useState(false)

  const me = useMe()
  const { data: practiceUserData } = useCachedPracticeUser(practiceId, me?.uid || null)
  const handleSelect = useCallback(() => {
    setSelectLoading(true)
    onSelect().finally(() => setSelectLoading(false))
  }, [onSelect])

  const { isMobile } = useContext(ScreenContext)
  return (
    <Stack
      px={3}
      bg="white"
      transition="all 300ms"
      align={isMobile ? 'flex-start' : 'center'}
      aria-label={data ? data.name || 'Unnamed Provider' : 'Loading...'}
      py={2}
      flexFlow={isMobile ? 'column' : 'row'}
      // spacing={0}
      w="100%"
      // align='flex-start'
    >
      {loading ? (
        <>
          <CircularProgress size={6} color={colors.green.hex} isIndeterminate />
          <Text color="gray.600">Loading practice info...</Text>
        </>
      ) : (
        <>
          {/* <HStack flex={1}> */}
          <Flex>
            <Badge bg={colors.green.hex} fontSize="xs" color="white">
              {practiceUserData?.role || 'User'}
            </Badge>
          </Flex>
          <Tooltip placement="top" hasArrow label="More details coming soon!">
            <Button size={isMobile ? 'sm' : 'md'} variant="link" lineHeight={1}>
              {data?.name}
            </Button>
          </Tooltip>
          {/* </HStack> */}
          <Center cursor="pointer" onClick={handleSelect} aria-label="Select" ml="auto">
            {selectLoading ? (
              <CircularProgress size={5} color={colors.green.hex} isIndeterminate />
            ) : (
              <HStack spacing={1}>
                {isSelected ? (
                  <Text fontSize="xs" color={colors.green.hex}>
                    ACTIVE
                  </Text>
                ) : null}
                <SelectedIndicatorSvg isSelected={isSelected} />
              </HStack>
            )}
          </Center>
        </>
      )}
    </Stack>
  )
}

export const UserPracticeAccess = () => {
  const { practiceAccess, selectedPracticeId } = usePracticeAccess()

  const { ref } = useAuth()
  const { showError } = useContext(PopUpMessageContext)
  const selectPractice = useCallback(
    async (id: string) => {
      if (!ref) {
        console.error('no ref')
        return
      }
      try {
        await updateDoc(ref, { selectedPracticeId: id })
      } catch (e: any) {
        showError(e?.message || 'Error selecting practice')
      }
    },
    [ref, showError],
  )

  const practiceIds = useMemo(() => Object.keys(practiceAccess || {}), [practiceAccess])

  const [viewedPracticeId, setViewedPracticeId] = React.useState<string | null>(null)

  return (
    <Expandable
      initExpanded
      headerProps={{ bg: 'gray.50', overflow: 'hidden' }}
      borderRadius={4}
      border="1px solid #cdcdcd"
      header={() => (
        <Text borderRadius={4} bg="gray.50" py={1} px={2} fontFamily="Comfortaa">
          My Practices
        </Text>
      )}>
      <VStack divider={<Divider />} fontFamily="Comfortaa" spacing={1} w="100%" align="flex-start">
        {practiceAccess && practiceIds?.length ? (
          <>
            {practiceIds.map(practiceId => (
              <PracticeAccessView
                isOpen={viewedPracticeId === practiceId}
                onOpen={() => {
                  setViewedPracticeId(practiceId)
                }}
                isSelected={selectedPracticeId === practiceId}
                onSelect={() => selectPractice(practiceId)}
                key={practiceId}
                practiceId={practiceId}
              />
            ))}
          </>
        ) : (
          <Text px={2} py={1}>
            You have not been added to a practice
          </Text>
        )}
      </VStack>
    </Expandable>
  )
}
