import { HStack, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

export const SelectPracticeView = () => (
  <HStack p={3}>
    <Text>
      <Link to="/settings">Select a practice</Link>
      to view invoices
    </Text>
  </HStack>
)
