import { Heading, Text, VStack } from '@chakra-ui/react'
import {
  Expandable,
  FloatingContainer,
  PageContainer,
  ScreenContext,
  UserPanel,
} from '@hb/shared-frontend'
import React, { useContext } from 'react'
import { UserPracticeAccess } from 'src/components/User/UserPracticeAccess'

export const SettingsView = () => {
  const { contentHeight } = useContext(ScreenContext)
  return (
    <PageContainer>
      <FloatingContainer maxH={`${contentHeight - 60}px`} overflowY="auto">
        <VStack spacing={2} w="100%" align="flex-start">
          <Heading fontFamily="Comfortaa" size="md">
            Settings
          </Heading>
          <UserPracticeAccess />
          <VStack w="100%" bg="gray.50" borderRadius={4} border="1px solid #cdcdcd">
            <Expandable
              header={() => (
                <Text fontFamily="Comfortaa" px={2} py={1}>
                  Contact Info
                </Text>
              )}>
              <UserPanel noSignOut />
            </Expandable>
          </VStack>
        </VStack>
      </FloatingContainer>
    </PageContainer>
  )
}
