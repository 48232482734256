import { Center, Portal } from '@chakra-ui/react'
import {
  AppHeader,
  Container,
  CookieBanner,
  EditableStateProvider,
  LoadingOverlay,
  Menu,
  PopUpMessageOverlay,
  RedirectView,
  RootModals,
  ScreenContext,
  SignInView,
  siteVersions,
  useAuth,
  View,
} from '@hb/shared-frontend'
import { MyThreadsListProvider } from '@hb/shared-frontend/components/Messaging/contexts'
import { MessagesWidget } from '@hb/shared-frontend/components/Messaging/MessagesWidget'
import { useFilteredViews } from '@hb/shared-frontend/hooks/useFilteredViews'
import { useScreen } from '@hb/shared-frontend/hooks/useScreen'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { views } from './views'

const App = () => {
  const filteredViews = useFilteredViews(views)

  const screen = useScreen()
  const { height } = screen

  const loading = useAuth(s => s.loading)
  return (
    <ScreenContext.Provider value={screen}>
      <EditableStateProvider>
        <Portal>
          <MyThreadsListProvider>
            <Container
              style={{
                justifyContent: 'flex-start',
                opacity: loading ? 0 : 1,
                transition: `opacity 333ms ease ${loading ? 0 : 333}ms`,
                boxSizing: 'border-box',
                height: '100vh',
                background: siteVersions.staging.background,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}>
              <AppHeader views={filteredViews} />
              <Center position="relative" w="100%" height={`${height - 55}px`}>
                <Switch>
                  {!loading
                    ? filteredViews.map(({ path, component }: View) => (
                        <Route
                          key={`route_to_${path}`}
                          exact={true}
                          path={path}
                          render={() => React.createElement(component)}
                        />
                      ))
                    : null}
                  <Route path="/sign-in" component={SignInView}></Route>

                  {!loading ? <Route render={() => <RedirectView loading={loading} />} /> : null}
                </Switch>
              </Center>
              <MessagesWidget />
              <Menu views={filteredViews} />
            </Container>
            <RootModals />
            <LoadingOverlay
              style={{ fontSize: '21px', fontFamily: 'Comfortaa' }}
              vertical
              height={100}
              loading={loading}
              text="Hamilton Billing"
            />
            <CookieBanner />
          </MyThreadsListProvider>
        </Portal>
        <PopUpMessageOverlay />
      </EditableStateProvider>
    </ScreenContext.Provider>
  )
}

export default App
