import {
  AnyObject,
  InsuranceProvider,
  MIDWIVES,
  Practice,
  PracticeUserRoleItem,
  UserRoleItem,
  USER_ROLES,
} from '@hb/shared'
import { collection, CollectionReference, doc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from '../../backend'
import { INSURANCE_PROVIDERS_REF, MIDWIVES_REF, USER_ROLES_REF } from '../firestoreCollections'

export type CachedCollectionStoreData<T extends AnyObject> = {
  subscribers: Record<string, Record<string, (data: T | null) => void>>
  snapshotListeners: Record<string, () => void>
  docs: Record<string, { data: T | null; isLoading: boolean }>
  subscribeToDoc: (id: string, callback: (data: T | null) => void) => () => void
}

const getUniqueSubscriberId = (existing: Array<string>) => {
  let id = Math.random().toString(36).substring(7)
  while (existing.includes(id)) {
    id = Math.random().toString(36).substring(7)
  }
  return id
}

const cached: Record<string, CachedCollectionStoreData<any>> = {}

const createCachedCollection = <T extends AnyObject>(
  ref: CollectionReference,
): CachedCollectionStoreData<T> => {
  const existing = cached[ref.path]
  if (existing) return existing
  cached[ref.path] = {
    subscribers: {},
    snapshotListeners: {},
    docs: {},
    subscribeToDoc: () => () => {},
  }

  const set = (
    data:
      | Partial<CachedCollectionStoreData<T>>
      | ((current: CachedCollectionStoreData<T>) => Partial<CachedCollectionStoreData<T>>),
  ) => {
    if (typeof data === 'function') {
      cached[ref.path] = {
        ...cached[ref.path],
        ...data(cached[ref.path]),
      }
    }
    cached[ref.path] = {
      ...cached[ref.path],
      ...data,
    }
  }

  cached[ref.path] = {
    ...cached[ref.path],
    subscribeToDoc: (id, callback) => {
      const { snapshotListeners, subscribers } = cached[ref.path]
      const subscriberId = getUniqueSubscriberId(Object.keys(subscribers))
      if (!snapshotListeners[id]) {
        const unsubscribe = onSnapshot(doc(ref, id), fetched => {
          const data = fetched.exists() ? (fetched.data() as T) : null
          set(state => {
            const listeners = state.subscribers[id]
            if (listeners) {
              Object.values(listeners).forEach(listener => listener(data))
            }
            return {
              ...state,
              docs: {
                ...state.docs,
                [id]: { data, isLoading: false },
              },
            }
          })
        })
        set(state => ({
          ...state,
          snapshotListeners: {
            ...state.snapshotListeners,
            [id]: unsubscribe,
          },
        }))
      }
      set(state => ({
        subscribers: {
          ...state.subscribers,
          [id]: {
            ...state.subscribers[id],
            [subscriberId]: callback,
          },
        },
      }))
      callback(cached[ref.path].docs[id]?.data ?? null)
      return () => {
        set(state => {
          const { [subscriberId]: _, ...rest } = state.subscribers?.[id] || {}
          return {
            ...state,
            subscribers: {
              ...state.subscribers,
              [id]: rest,
            },
          }
        })
      }
    },
  }
  return cached[ref.path]
}

const getCachedCollection = <T extends AnyObject>(ref: CollectionReference) => {
  const existing = cached[ref.path]
  if (existing) return existing
  return createCachedCollection<T>(ref)
}

export const useCachedDoc = <T extends AnyObject>(
  collectionRef: CollectionReference,
  id: string | null,
) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<T | null>(null)
  // const [error, setError] = useState<string | null>(null)
  useEffect(() => {
    if (!id) {
      setLoading(false)
      setData(null)
      return () => {}
    }
    setLoading(true)
    return getCachedCollection(collectionRef).subscribeToDoc(id, u => {
      setData(u)
      setLoading(false)
    })
  }, [collectionRef, id])

  return { data, loading }
}

export const getCachedDoc = <T extends AnyObject>(
  collectionRef: CollectionReference,
  id: string,
) => {
  const cachedCollection = getCachedCollection<T>(collectionRef)
  const cachedData = cachedCollection.docs[id]
  if (cachedData) return cachedData
  return {
    data: null,
    isLoading: true,
  }
}

export const useCachedUser = (id: string | null) => useCachedDoc<UserRoleItem>(USER_ROLES_REF, id)
export const useCachedPractice = (id: string | null) => useCachedDoc<Practice>(MIDWIVES_REF, id)
export const useCachedInsurer = (id: string | null) =>
  useCachedDoc<InsuranceProvider>(INSURANCE_PROVIDERS_REF, id)
export const useCachedPracticeUser = (practiceId: string, userId: string | null) =>
  useCachedDoc<PracticeUserRoleItem>(
    collection(db, `${MIDWIVES}/${practiceId}/${USER_ROLES}`),
    userId,
  )
