import React, { CSSProperties, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useResizeObserver } from '../../../hooks'
import './styles.css'

const MIN_LOADING_TIME = 1000

export const LoadingText = ({
  text,
  placeholder,
  loading,
  style,
  noDelay,
  noWrap,
}: {
  text?: string
  placeholder: string
  noDelay?: boolean
  loading: boolean
  style?: CSSProperties
  noWrap?: boolean
}) => {
  const placeholderWords = useMemo(() => placeholder.split(' '), [placeholder])

  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setTimeout(
      () => {
        setMounted(true)
      },
      noDelay ? 1 : MIN_LOADING_TIME,
    )
  }, [noDelay])

  const usedLoading = useMemo(() => loading || !mounted, [loading, mounted])
  const textStyle = useMemo(
    () => ({
      ...style,
      opacity: usedLoading ? 0 : 1,
      transition: 'opacity 333ms ease',
    }),
    [style, usedLoading],
  )
  const placeholderStyle = useMemo(() => ({ ...style, opacity: 0 }), [style])
  const bodyRef = useRef<HTMLDivElement>(null)
  const { height: bodyHeight } = useResizeObserver(bodyRef, 'content')
  return (
    <div
      style={{
        position: 'relative',
        height: `${bodyHeight}px`,
        transition: 'height 400ms',
      }}>
      <div ref={bodyRef}>
        <span style={textStyle}>{usedLoading ? placeholder : text}</span>
      </div>
      <div
        style={{
          opacity: usedLoading ? 1 : 0,
          transition: 'opacity 333ms ease',
          display: noWrap ? 'flex' : 'block',
          flexDirection: noWrap ? 'row' : 'unset',
          whiteSpace: noWrap ? 'nowrap' : 'pre-wrap',
          gap: noWrap ? '0.28rem' : 0,
        }}
        className="loading-container">
        {placeholderWords.map((word, index) => (
          <Fragment key={index}>
            {index ? <span style={placeholderStyle}> </span> : null}
            <div className="loading-span">
              <span style={placeholderStyle}>{word}</span>
              <div className="shiny-effect" />
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  )
}
