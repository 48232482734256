import {
  AddPracticeVisitArgs,
  AddReceivedPaymentArgs,
  AddUsersToThreadArgs,
  CombineFilesIntoPdfArgs,
  ConfirmPaymentInstallmentArgs,
  CopyChatFileToAssessmentArgs,
  CreateNewThreadArgs,
  DeleteThreadMessageArgs,
  EmptyObject,
  MakeCoveragePrimaryArgs,
  NotificationTopic,
  RegisterWebPushNotificationTokenArgs,
  RemoveUsersFromThreadArgs,
  ReportDeliveryArgs,
  ReportPaymentInstallmentArgs,
  ReportPracticeFileUploadArgs,
  ReportProfilePictureUploadArgs,
  RequestCallInArgs,
  RequestCoverageArgs,
  RequestVisitPdfArgs,
  SendThreadMessageArgs,
  ThreadSearchResult,
  ThreadType,
  UpdateAdminColorArgs,
  UpdateSharedNoteArgs,
} from '@hb/shared'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { app } from './app'

export const functions = getFunctions(app)

export const manuallyAddPaymentToInvoice = httpsCallable<{
  assessmentId: string
  paymentId: string
}>(functions, 'manuallyAddPaymentToInvoice')
export const removeManualPaymentFromInvoice = httpsCallable<{
  assessmentId: string
  paymentId: string
  invoiceId: string
}>(functions, 'removeManualPaymentFromInvoice')
export const assignFaxToAssessment = httpsCallable<{
  faxId: string
  assessmentId: string | null
}>(functions, 'assignFaxToAssessment')

export const createInvoicePreview = httpsCallable<{ invoiceId: string }, string>(
  functions,
  'createInvoicePreview',
)
export const addReceivedPayment = httpsCallable<AddReceivedPaymentArgs>(
  functions,
  'addReceivedPayment',
)

export const deleteReceivedPayment = httpsCallable<{
  assessmentId: string
  paymentId: string
}>(functions, 'deleteReceivedPayment')

export const updateAssessmentMiscarriedStatus = httpsCallable<{
  assessmentId: string
  miscarried: boolean
}>(functions, 'updateAssessmentMiscarriedStatus')

export const updateAssessmentDeliveryStatus = httpsCallable<ReportDeliveryArgs>(
  functions,
  'updateAssessmentDeliveryStatus',
)

export const sendMessageFunction = httpsCallable<SendThreadMessageArgs, string>(
  functions,
  'sendThreadMessage',
)

export const deleteMessageFunction = httpsCallable<DeleteThreadMessageArgs, { success: string }>(
  functions,
  'deleteThreadMessage',
)

export const createNewThreadFunction = httpsCallable<CreateNewThreadArgs>(
  functions,
  'createNewThread',
)
export const deleteThreadFunction = httpsCallable<
  { threadId: string; threadType: ThreadType },
  void
>(functions, 'deleteThread')

export const updateAdminColorFunction = httpsCallable<UpdateAdminColorArgs>(
  functions,
  'updateAdminColor',
)

export const addUsersToThread = httpsCallable<AddUsersToThreadArgs>(functions, 'addUsersToThread')
export const removeUsersFromThread = httpsCallable<RemoveUsersFromThreadArgs>(
  functions,
  'removeUsersFromThread',
)
export const reportProfilePictureUpload = httpsCallable<ReportProfilePictureUploadArgs>(
  functions,
  'reportProfilePictureUpload',
)

export const searchMyThreads = httpsCallable<{ query: string }, ThreadSearchResult[]>(
  functions,
  'searchMyThreads',
)

export const copyChatFileToAssessment = httpsCallable<CopyChatFileToAssessmentArgs>(
  functions,
  'copyChatFileToAssessment',
)

// authentication
export const removeSecondaryFactor = httpsCallable<{ factorId: string }, void>(
  functions,
  'removeSecondaryFactor',
)
export const renameSecondaryFactor = httpsCallable<{ factorId: string; displayName: string }, void>(
  functions,
  'renameSecondaryFactor',
)
export const generatePDF = httpsCallable<{ html: string }, { path: string }>(
  functions,
  'generatePDF',
)

export const reportPracticeFileUpload = httpsCallable<ReportPracticeFileUploadArgs>(
  functions,
  'reportPracticeFileUpload',
)

export const fetchWebpageMetadata = httpsCallable<
  { url: string },
  { title: string; description: string; iconUrl: string }
>(functions, 'fetchWebpageMetadata')

export const registerWebPushNotificationToken = httpsCallable<
  RegisterWebPushNotificationTokenArgs,
  void
>(functions, 'registerWebPushNotificationToken')
export const unregisterWebPushNotificationToken = httpsCallable<EmptyObject, void>(
  functions,
  'unregisterWebPushNotificationToken',
)

export const updateWebPushNotificationSettings = httpsCallable<
  { topic: NotificationTopic; enabled: boolean },
  void
>(functions, 'updateWebPushNotificationSettings')

export const reportPaymentInstallment = httpsCallable<ReportPaymentInstallmentArgs, void>(
  functions,
  'reportPaymentInstallment',
)
export const confirmPaymentInstallment = httpsCallable<ConfirmPaymentInstallmentArgs, void>(
  functions,
  'confirmPaymentInstallment',
)
export const combineFilesIntoPdf = httpsCallable<CombineFilesIntoPdfArgs, void>(
  functions,
  'combineFilesIntoPdf',
)

export const addPracticeVisit = httpsCallable<
  AddPracticeVisitArgs,
  { success: string; id: string; error?: never } | { error: string; id?: never; success?: never }
>(functions, 'addPracticeVisit')
export const requestVisitPdf = httpsCallable<
  RequestVisitPdfArgs,
  { success: boolean; storagePath: string }
>(functions, 'requestVisitPdf')

export const fixAssessmentFiles = httpsCallable<
  { assessmentId: string },
  { numFixedFiles: number; numFixedValues: number }
>(functions, 'fixAssessmentFiles')
export const fixAssessmentInvoiceSnippets = httpsCallable<{ assessmentId: string }, void>(
  functions,
  'fixAssessmentInvoiceSnippets',
)

export const requestCoverage = httpsCallable<RequestCoverageArgs, { success: boolean }>(
  functions,
  'requestCoverage',
)
export const makeCoveragePrimary = httpsCallable<MakeCoveragePrimaryArgs, { success: string }>(
  functions,
  'makeCoveragePrimary',
)
export const requestCallIn = httpsCallable<RequestCallInArgs, { success: string }>(
  functions,
  'requestCallIn',
)
export const sendCallInRequestEmail = httpsCallable<{
  coverageId: string
  assessmentId: string
  message: string
}>(functions, 'sendCallInRequestEmail')
export const updateSharedNote = httpsCallable<UpdateSharedNoteArgs, void>(
  functions,
  'updateSharedNote',
)
