import { ChevronDownIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackDivider,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import {
  AppName,
  colors,
  getDateString,
  getDateTimeString,
  getFullName,
  getTimeString,
  getValidUrl,
  messageThreadCollections,
  PopulatedThreadMessage,
  Reaction,
  reactionIcons,
  splitTextLines,
  splitTextParts,
  ThreadMessageReplyTo,
} from '@hb/shared'
import { collection, doc } from 'firebase/firestore'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { db } from '../../../backend/db'
import { useCachedUser } from '../../../collections/hooks/cached'
import { PopUpMessageContext } from '../../../contexts'
import { useApp } from '../../../contexts/AppContext'
import replyIcon from '../../../icons/reply.svg'
import { SvgIcon } from '../../../icons/SvgIcon'
import { useAuth } from '../../../store'
import { xor } from '../../../utils/data'
import { ProfilePicturePopover } from '../../Admins/ProfilePicturePopover'
import { DeleteConfirmAlert } from '../../Buttons/DeleteButton'
import { Reactions } from '../../Reactions'
import { useAddReaction } from '../../Reactions/hooks'
import { ParsedText } from '../../shared/ParsedText'
import { LinkPreview } from '../../shared/SiteLink'
import { isHBSite } from '../../shared/SiteLink/utils'
import { deleteThreadMessage, reportMessageRead } from '../utils'
import { AttachedFiles } from './AttachedFiles'
import { ThreadViewContext } from './contexts'
import { ReadByPopover } from './ReadByPopover'

const getRightJustified = (appName: AppName, message: PopulatedThreadMessage) => {
  switch (message?.threadType) {
    case 'assessment':
      return xor(message.createdByGroup === 'admin', appName === 'providers-app')
    default:
      return message.sentByMe
  }
}

const MessageLinkPreview = ({ message }: { message: PopulatedThreadMessage }) => {
  const { text } = message || {}
  const firstLinkUrl = useMemo(() => {
    if (!text) return null
    const lines = splitTextLines(text || '')
    for (let i = 0; i < lines.length; i += 1) {
      const parts = splitTextParts(lines[i])
      const [firstLink] = parts.map(p => getValidUrl(p)).filter(link => link && !isHBSite(link))
      if (firstLink) return firstLink
    }
    return null
  }, [text])

  if (!firstLinkUrl) return null

  return (
    <Box pb={1}>
      <LinkPreview href={firstLinkUrl} />
    </Box>
  )
}

const ThreadMessageMoreActionsMenu = ({ message }: { message: PopulatedThreadMessage }) => {
  const { sentByMe } = message || {}
  const { setReplyingTo, setEditingMessage } = useContext(ThreadViewContext)
  const [confirmingDelete, setConfirmingDelete] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const { showMessage } = useContext(PopUpMessageContext)
  const handleDelete = useCallback(() => {
    setDeleting(true)
    deleteThreadMessage(message)
      .then(() => setConfirmingDelete(false))
      .catch(err => {
        setDeleting(false)
        showMessage({ text: 'Error deleting message', subText: err.message, type: 'error' })
      })
  }, [message, showMessage])
  return (
    <>
      <Popover trigger="hover" placement="bottom" strategy="fixed" isLazy>
        <PopoverTrigger>
          <IconButton
            variant="unstyled"
            aria-label="more actions"
            minW="0"
            w="30px"
            height="30px"
            display="flex"
            icon={
              <Text fontSize="lg" color="gray.600">
                ⋮
              </Text>
            }
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            bg="gray.50"
            borderRadius="20px"
            overflow="hidden"
            border="none"
            filter="drop-shadow(1px 1px 4px #00000066)"
            w="160px">
            <VStack divider={<Divider />} spacing={0} w="100%">
              <Button
                size="sm"
                w="100%"
                variant="unstyled"
                _hover={{
                  bg: 'gray.100',
                }}
                display="flex"
                gap={1}
                onClick={() => setReplyingTo(message)}
                justifyContent="center"
                aria-label="reply">
                <SvgIcon width="18px" height="18px" opacity={0.7} src={replyIcon} />
                <Text fontSize="sm" color="gray.600">
                  Reply
                </Text>
              </Button>
              {sentByMe ? (
                <Button
                  size="sm"
                  w="100%"
                  variant="unstyled"
                  _hover={{
                    bg: 'gray.100',
                  }}
                  onClick={() => setEditingMessage(message)}
                  display="flex"
                  gap={1}
                  justifyContent="center"
                  aria-label="edit">
                  <EditIcon color="gray.600" />
                  <Text fontSize="sm" color="gray.600">
                    Edit
                  </Text>
                </Button>
              ) : null}
              {sentByMe ? (
                <Button
                  size="sm"
                  w="100%"
                  variant="unstyled"
                  _hover={{
                    bg: 'gray.100',
                  }}
                  display="flex"
                  gap={1}
                  color="red.600"
                  onClick={() => setConfirmingDelete(true)}
                  justifyContent="center"
                  aria-label="delete">
                  <DeleteIcon w={3} color="red.600" />
                  <Text fontSize="sm">Delete</Text>
                </Button>
              ) : null}
            </VStack>
          </PopoverContent>
        </Portal>
      </Popover>
      {confirmingDelete ? (
        <DeleteConfirmAlert
          confirmActive={confirmingDelete}
          itemName="message"
          actionLoading={deleting}
          onConfirm={handleDelete}
          onCancel={() => setConfirmingDelete(false)}
        />
      ) : null}
    </>
  )
}

const ThreadMessageMenu = ({
  rightJustified,
  message,
}: {
  rightJustified: boolean
  message: PopulatedThreadMessage
}) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setTimeout(() => setMounted(true), 10)
  }, [])
  const docRef = useMemo(
    () =>
      doc(
        collection(db, messageThreadCollections[message.threadType], message.threadId, 'messages'),
        message.messageGroupId,
      ),
    [message],
  )
  const { addReaction, addingReaction } = useAddReaction(
    docRef,
    `messages.${message.createdOn}.reactions`,
  )
  return (
    <HStack
      position="absolute"
      opacity={mounted ? 1 : 0}
      zIndex={1}
      bg="white"
      pointerEvents={mounted ? 'auto' : 'none'}
      boxShadow="0px 0px 3px rgba(0,0,0,0.3)"
      left={rightJustified ? '-80px' : 'unset'}
      right={rightJustified ? 'unset' : '-80px'}
      flexFlow={rightJustified ? 'row-reverse' : 'row'}
      justifyContent={rightJustified ? 'flex-end' : 'flex-start'}
      top="4px"
      spacing={0}
      transition="opacity 0.3s"
      divider={<StackDivider />}
      borderRadius="full">
      <ThreadMessageMoreActionsMenu message={message} />
      <Popover trigger="hover" placement="bottom" strategy="fixed" isLazy>
        <PopoverTrigger>
          <Button px={1} variant="unstyled" height="30px" display="flex">
            <Text fontSize="sm" filter="grayscale(100%)">
              🙂
            </Text>
            <ChevronDownIcon color="gray.600" />
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent
            bg="gray.100"
            borderRadius="20px"
            overflow="hidden"
            border="none"
            filter="drop-shadow(1px 1px 4px #00000066)"
            w="200px">
            <VStack spacing={0} w="100%">
              <Text py={1} fontSize="sm" fontWeight={600} color="gray.600">
                REACTIONS
              </Text>
              <Flex justifyContent="center" flexFlow="row wrap" w="100%">
                {Object.keys(reactionIcons).map(type => (
                  <IconButton
                    size="sm"
                    borderRadius="full"
                    key={type}
                    boxShadow="inset 1px 1px 3px #00000033"
                    bg="gray.50"
                    aria-label={type}
                    icon={<Box>{reactionIcons[type as Reaction['type']]}</Box>}
                    onClick={() => addReaction(type as Reaction['type'])}
                    isLoading={addingReaction}
                  />
                ))}
              </Flex>
            </VStack>
          </PopoverContent>
        </Portal>
      </Popover>
    </HStack>
  )
}

const MessageReplyToView = ({ replyTo }: { replyTo: ThreadMessageReplyTo }) => {
  const { handleMessageSelect } = useContext(ThreadViewContext)
  const numAttachedFiles = Object.keys(replyTo.message.attachedFiles || {}).length
  return (
    <Flex pb={2} w="100%">
      <Flex
        cursor="pointer"
        onClick={() => handleMessageSelect(replyTo.messageId, true)}
        boxShadow="1px 1px 3px #00000066"
        w="100%"
        borderRadius={4}
        bg="gray.50"
        p={1}
        px={2}
        flexDir="column">
        <HStack w="100%">
          <Text flex={1} whiteSpace="nowrap" fontSize="sm" color="gray.500">
            {replyTo.authorFirstName} {replyTo.authorLastName}:
          </Text>
          {numAttachedFiles ? (
            <AttachedFiles attachedFiles={replyTo.message.attachedFiles} isPreview />
          ) : null}
        </HStack>
        <Text fontSize="sm" lineHeight={1.2} color="gray.800" fontFamily="hero-new" noOfLines={2}>
          {replyTo.message.text}
        </Text>
      </Flex>
    </Flex>
  )
}

export const ThreadMessageView = ({
  message,
  horizontal,
  isPreview,
}: {
  message: PopulatedThreadMessage
  horizontal?: boolean
  isPreview?: boolean
}) => {
  const { data: user, loading } = useCachedUser(message.createdBy)
  const { appName } = useApp()
  const {
    sentAtSameDateAsPrevious,
    sentByMe,
    sentBySameAsPrevious,
    createdOn,
    replyTo,
    attachedFiles,
    reactions,
    text,
  } = message

  const me = useAuth(s => s.authUser)

  const docRef = useMemo(
    () =>
      doc(
        collection(db, messageThreadCollections[message.threadType], message.threadId, 'messages'),
        message.messageGroupId,
      ),
    [message],
  )

  const [hovered, setHovered] = useState(false)
  const rightJustified = getRightJustified(appName, message)
  const alignVert = rightJustified ? 'flex-end' : 'flex-start'
  let userName = user ? getFullName(user) : 'Loading sender name...'
  if (!loading && !user) {
    userName = 'Deleted User'
  }
  const bubbleAlign = useMemo(() => {
    if (horizontal) return 'center'
    return rightJustified ? 'flex-end' : 'flex-start'
  }, [rightJustified, horizontal])

  // update read status
  useEffect(() => {
    if (!me) return
    if (message.createdBy === me.uid) return
    let shouldUpdate = !message.readBy?.includes(me.uid)
    if (message.editedOn) {
      shouldUpdate = shouldUpdate || !message.editedReadBy?.includes(me.uid)
    }
    if (shouldUpdate) {
      reportMessageRead(message)
    }
  }, [message, me])

  return (
    <Flex
      id={`message-${createdOn}`}
      px={2}
      onMouseLeave={() => setHovered(false)}
      minW="0"
      position="relative"
      align={horizontal ? 'center' : alignVert}
      width="100%"
      // flex={horizontal ? 1 : undefined}
      gap={horizontal ? 1 : 0}
      flexFlow={horizontal ? 'row' : 'column'}
      py={horizontal ? 0 : '0.2rem'}>
      {sentAtSameDateAsPrevious || horizontal ? null : (
        <Center w="100%" px={1}>
          <Text whiteSpace="nowrap" fontSize="xs" py={1} color="gray.500" fontFamily="hero-new">
            {getDateString(message.createdOn, 'long')}
          </Text>
        </Center>
      )}
      {!horizontal && (sentBySameAsPrevious || sentByMe) ? null : (
        <HStack flexFlow={rightJustified ? 'row-reverse' : 'row'} spacing={1} px={1}>
          {horizontal ? (
            <ProfilePicturePopover noPopover={isPreview} userId={message.createdBy} />
          ) : (
            <HStack pb={message.deletedOn ? 0 : 1}>
              {message.deletedOn ? null : (
                <ProfilePicturePopover noPopover={isPreview} size={8} userId={message.createdBy} />
              )}
              <Text
                whiteSpace="nowrap"
                fontSize={message.deletedOn ? 'sm' : 'md'}
                color="gray.600"
                fontFamily="Hero-New">
                {userName}
              </Text>
            </HStack>
          )}
        </HStack>
      )}
      <HStack
        justify={rightJustified ? 'flex-end' : 'flex-start'}
        w={horizontal ? 'unset' : '100%'}
        flex={horizontal ? 1 : 'unset'}
        minW="0">
        {/* <Tooltip
          // bg={color}
          bg="gray.50"
          // color='white'
          color="gray.600"
          // textShadow={'0px 0px 3px black'}
          placement={rightJustified ? 'top-end' : 'top-start'}
          label={getDateTimeString(message.createdOn, 'long', true)}
          hasArrow
        > */}
        <Box
          bg="white"
          w={horizontal ? '100%' : 'auto'}
          minW={replyTo && !message.deletedOn ? '200px' : '0'}
          borderRadius="12px"
          maxW={horizontal ? '100%' : '70%'}
          onMouseEnter={() => setHovered(true)}
          // minW=""
          position="relative"
          boxShadow={`${rightJustified ? 1 : -1}px 1px 3px rgba(0,0,0,0.3)`}>
          <Flex
            borderRadius="12px"
            py={2}
            gap="0.18rem"
            flexFlow={horizontal ? 'row' : 'column'}
            justify={horizontal ? 'space-between' : 'flex-start'}
            px={3}
            align={bubbleAlign}
            bg={sentByMe ? `${colors.green.hex}22` : '#ffffff'}>
            {replyTo && !isPreview && !horizontal && !message.deletedOn ? (
              <MessageReplyToView replyTo={replyTo} />
            ) : null}
            {message.deletedOn || horizontal ? null : (
              <AttachedFiles isPreview={isPreview} attachedFiles={attachedFiles} />
            )}
            <MessageLinkPreview message={message} />
            <Box
              // flex={horizontal ? 1 : 'unset'}
              whiteSpace={horizontal ? 'nowrap' : 'pre-wrap'}
              isTruncated={!!horizontal}
              lineHeight={1.2}
              w="100%"
              textAlign={rightJustified ? 'right' : 'left'}
              fontSize="sm"
              color={message.deletedOn ? 'gray.500' : 'gray.800'}
              fontStyle={message.deletedOn ? 'italic' : 'normal'}
              fontFamily="hero-new">
              {message.deletedOn ? 'Message Deleted' : <ParsedText>{text}</ParsedText>}
            </Box>
            {message.deletedOn ? null : (
              <HStack
                spacing={1}
                height="16px"
                borderLeft={horizontal ? '1px solid #00000033' : 'none'}
                pl={horizontal ? 1 : 0}
                justify={'flex-end'}
                w={horizontal ? 'auto' : '100%'}
                // flexFlow={rightJustified ? 'row-reverse' : 'row'}
              >
                {message.editedOn ? (
                  <Tooltip
                    placement="bottom"
                    hasArrow
                    bg="gray.50"
                    color="gray.600"
                    label={`Edited ${getDateTimeString(message.editedOn, 'short')}`}>
                    <Text
                      whiteSpace="nowrap"
                      lineHeight={1}
                      fontStyle="italic"
                      color="gray.500"
                      fontSize="xs">
                      Edited
                    </Text>
                  </Tooltip>
                ) : null}
                <Text
                  title={getDateTimeString(message.createdOn, 'long')}
                  whiteSpace="nowrap"
                  lineHeight={1}
                  color="gray.500"
                  fontSize="xs">
                  {getTimeString(message.createdOn)}
                </Text>
                <ReadByPopover type={message.threadType} message={message} />
              </HStack>
            )}
            {reactions?.length && !isPreview && !message.deletedOn ? (
              <Flex
                left={rightJustified ? '2px' : '-4px'}
                position="relative"
                top="6px"
                justify={rightJustified ? 'flex-end' : 'flex-start'}
                align="flex-end"
                w="100%"
                height="10px">
                <Reactions
                  docRef={docRef}
                  fieldPath={`messages.${message.createdOn}.reactions`}
                  reactions={reactions || []}
                />
              </Flex>
            ) : null}
            {hovered && !isPreview && !message.deletedOn ? (
              <ThreadMessageMenu message={message} rightJustified={rightJustified} />
            ) : null}
          </Flex>
        </Box>
        {/* </Tooltip> */}
      </HStack>
    </Flex>
  )
}
