import { CheckIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  CircularProgress,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { colors, isNotProd, releaseDate, version } from '@hb/shared'
import React, { useCallback, useContext, useMemo } from 'react'
import { ScreenContext, useApp } from '../../contexts'
import { CollapseError } from '../CollapseError'

export const AppVersionView = () => {
  const { appVersion, appName } = useApp()
  const { loading, data, error } = appVersion
  const { isMobile } = useContext(ScreenContext)

  const hardReload = useCallback(() => {
    window.location.reload()
  }, [])

  const versionMatches = useMemo(
    () => appVersion.data?.dateString === releaseDate,
    [appVersion.data],
  )

  return (
    <Popover trigger="hover" placement="right-start" strategy="fixed">
      <PopoverTrigger>
        <Button
          gap={2}
          size="sm"
          minW="0"
          minH="0"
          width={versionMatches ? '32px' : 'auto'}
          height="32px"
          cursor="pointer"
          bg="gray.50"
          onClick={versionMatches ? () => {} : hardReload}
          borderRadius="full"
          filter="drop-shadow(1px 1px 3px #00000077)">
          {error || (!data && loading) ? (
            <>
              <Tooltip
                color={error ? 'red.500' : 'gray.600'}
                label={error || 'Loading app version'}>
                <CircularProgress
                  isIndeterminate
                  color={colors.green.hex}
                  size={5}
                  display={loading ? 'block' : 'none'}
                />
              </Tooltip>
            </>
          ) : (
            <>
              {versionMatches ? (
                <CheckIcon color={colors.green.hex} />
              ) : (
                <WarningIcon color="red.400" width={5} height={5} />
              )}
              {versionMatches || isMobile ? null : (
                <Text
                  fontSize={isMobile ? 'xs' : 'sm'}
                  whiteSpace="nowrap"
                  fontWeight={600}
                  cursor="inherit"
                  color="red.500">
                  VERSION MISMATCH
                </Text>
              )}
            </>
          )}
        </Button>
      </PopoverTrigger>
      <Portal appendToParentPortal>
        <PopoverContent>
          <VStack spacing={0} p={3}>
            <Text
              fontWeight={600}
              fontSize="sm"
              color={versionMatches ? colors.green.hex : 'red.600'}>
              {versionMatches ? 'Currently on the correct version!' : 'Click to reload version'}
            </Text>
            <Text color="gray.500" fontSize="sm">
              Most recent version: {data?.dateString}
            </Text>
            <Text color="gray.500" fontSize="sm">
              Loaded version: {releaseDate}
            </Text>
            {appName === 'app' && isNotProd ? (
              <Center py={2} fontSize="lg" zIndex={2} color={'salmon'}>
                <HStack spacing={2}>
                  <HStack spacing={0}>
                    <WarningTwoIcon mr={2} />
                    <VStack spacing={0}>
                      <Text lineHeight={1} fontFamily="Comfortaa" fontSize="sm" fontWeight={600}>
                        TEST SITE | {version === 'dev' ? 'TEST' : 'REAL'} DATA
                      </Text>
                    </VStack>
                    <WarningTwoIcon ml={2} />
                  </HStack>
                </HStack>
              </Center>
            ) : null}
            <CollapseError error={error} />
          </VStack>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
