import { Box, Flex, FlexProps, HStack } from '@chakra-ui/react'
import { MIDWIVES, Practice } from '@hb/shared'
import React from 'react'
import { useDocument } from '../../../hooks'
import { CollapseError } from '../../CollapseError'
import { LoadingText } from '../../shared/LoadingText'
import { PracticeLogoView } from './PracticeLogoView'

const loremIpsum =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'

export const PracticeView = ({ id, ...props }: { id: string } & FlexProps) => {
  const { data: practice, loading, error } = useDocument<Practice>(MIDWIVES, id)
  return (
    <Flex flexFlow="column" {...props}>
      <CollapseError error={error} />
      {/* <HStack>
          <Text>
            Header Stuff
          </Text>
        </HStack> */}
      <HStack align="flex-start" w="100%">
        <PracticeLogoView practiceId={id} practice={practice} loading={loading} />
        <Flex flexFlow="column" p={2} flex={1}>
          <HStack w="100%">
            {/* <Text
              fontSize="lg"
              flex={1}
              isTruncated
              minW="0"
              fontFamily="Hero-New"
            >
              {practice?.name}
            </Text> */}
            <Box flex={1} minW="0">
              <LoadingText
                loading={loading && !practice}
                text={practice?.name}
                noWrap
                placeholder="Practice Midwifery NYC"
                style={{
                  fontSize: '1.4rem',
                  fontFamily: 'Hero-New',
                  minWidth: 0,
                  textOverflow: 'ellipsis',
                }}
              />
            </Box>
          </HStack>
          <Box bg="#fbfbfb" p={2} border="1px solid #cdcdcd" minH="70px" borderRadius={4}>
            {/* <Text fontWeight={400} fontFamily="Hero-New" color="gray.600">
              practice description goes here
            </Text> */}
            <LoadingText
              text={practice?.description || 'More info coming soon!'}
              loading={loading && !practice}
              placeholder={loremIpsum}
              style={{
                fontFamily: 'Hero-New',
                color: 'gray.400',
                fontWeight: 400,
              }}
            />
          </Box>
        </Flex>
      </HStack>
    </Flex>
  )
}

export const PracticeViewItem = ({ id }: { id: string }) => (
  <Box maxW="600px" w="100%" p={2}>
    <Flex
      w="100%"
      bg="gray.50"
      borderRadius={6}
      p={3}
      overflow="hidden"
      boxShadow="1px 1px 4px #00000077">
      <PracticeView id={id} />
    </Flex>
  </Box>
)
